import { css } from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const CustomStyle = css`
  &::before {
    background-image: linear-gradient(0deg,rgb(0 0 0 / 0.6),rgb(0 0 0 / 0.5));

    @media ${device.tablet} {
      background-blend-mode: multiply, normal;
      background-image: linear-gradient(0deg,rgb(22 24 37 / 0.6),rgb(22 24 37 / 0.6));
    }
  }

  >.container {
    padding-bottom: 0;
    padding-top: 0;
  }
`
