import React, { useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import './PrivacyPolicy.scss'

type MenuContentProps = {
  anchor: string;
  label: string;
};

type PrivacyPolicyProps = {
  title?: string;
  htmlContent?: string;
  menuContent: MenuContentProps[];
};

export const PrivacyPolicy = ({
  title,
  htmlContent,
  menuContent,
}: PrivacyPolicyProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ activeItem, setActiveItem ] = useState(0)

  function handleClick (item: MenuContentProps, index: number) {
    setActiveItem(index)
    window && sendDatalayerEvent({
      section: `dobra_${index}`,
      section_name: item.label,
      element_action: 'click button',
      element_name: item.label,
      c_page: window.location.href,
    })
  }

  return (
    <div className='privacy-policy row py-4'>
      <div className='col-12 col-md-5 col-lg-4 px-0 px-md-3'>
        <div className='box-shadow'>
          <h3 className='fs-20 lh-25 fw-600 mb-0'>{title}</h3>
          <ol>
            {menuContent.map((item: MenuContentProps, index: number) => {
              return (
                <li className='fs-14 lh-18 fw-400' key={item.anchor}>
                  <a
                    href={`#${item.anchor}`}
                    className={`${
                      activeItem === index ? 'active' : ''
                    } text-gray--600`}
                    onClick={() => handleClick(item, index)}
                  >
                    {item.label}
                  </a>
                </li>
              )
            })}
          </ol>
        </div>
      </div>
      <div className='col-12 col-md-7 col-lg-8 mt-3 mt-md-0 px-0 pr-md-3'>
        <div className='box-shadow'>
          {htmlContent && (
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          )}
        </div>
      </div>
    </div>
  )
}
